@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  text-decoration: none;
  font-family: 'Heebo';
}

a {
  color: #524e61;
  &:visited {
    color: #524e61;
  }
}