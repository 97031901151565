@import '../../partials/_mixins.scss';
@import '../../partials/_colors.scss';

.about {
  height: 85vh;
  width: 100%;
  color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-size: 24px;
    font-weight: 600;
    padding-top: 10vh;
    @include media($tabdesk) {
      font-size: 36px;
      font-weight: 500;
    }
  }
  &__body {
    margin-top: 18px;
    font-size: 14px;
    width: 280px;
    text-align: center;
    @include media($tabdesk) {
      font-size: 20px;
      font-weight: 300;
      width: 500px;
    }
  }
}