@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap);
*{margin:0;padding:0;border:0;box-sizing:border-box;font-size:100%;vertical-align:baseline;list-style:none;text-decoration:none;font-family:'Heebo'}a{color:#524e61}a:visited{color:#524e61}

.App{background-image:url(/static/media/hero.21442377.jpg);background-size:cover;background-position:25% center;background-attachment:fixed;background-repeat:no-repeat;height:100%;width:100%}@media (min-width: 700px){.App{background-position:center center}}.App__filter{background-color:#FFFFFF90}

.hero{height:100vh;width:100%;color:#524e61;display:flex;justify-content:center;align-items:center;position:relative}.hero__header{display:flex;flex-direction:column;align-items:flex-end;font-size:40px;font-weight:400;width:125px}@media (min-width: 700px){.hero__header{font-size:60px}}.hero__divider{height:120px;width:1px;border:1px solid #524e61;margin:0px 20px}@media (min-width: 700px){.hero__divider{height:200px}}.hero__socials{display:flex;flex-direction:column;align-items:flex-start;font-size:11px;width:125px}@media (min-width: 700px){.hero__socials{font-size:16px}}.hero__socials-line{display:flex;margin:5px 0px;align-items:center}.hero__socials-icon{width:20px;height:20px;margin-right:5px}.hero__icon{position:absolute;bottom:175px;height:20px}@media (min-width: 700px){.hero__icon{height:20px;bottom:125px}}

.about{height:85vh;width:100%;color:#524e61;display:flex;flex-direction:column;align-items:center}.about__header{font-size:24px;font-weight:600;padding-top:10vh}@media (min-width: 700px){.about__header{font-size:36px;font-weight:500}}.about__body{margin-top:18px;font-size:14px;width:280px;text-align:center}@media (min-width: 700px){.about__body{font-size:20px;font-weight:300;width:500px}}

.projects{width:100%;color:#524e61;display:flex;flex-direction:column;align-items:center;padding-bottom:120px}.projects__header{font-size:24px;font-weight:600;padding-top:10vh}@media (min-width: 700px){.projects__header{font-size:36px;font-weight:500}}.projects__title{font-size:16px;font-weight:400;margin-top:16px;color:#524e61;margin-bottom:24px;border-bottom:1px solid #524e61}@media (min-width: 700px){.projects__title{font-size:24px;margin-top:164px}}

.inventree{width:100%;color:#524e61;display:flex;flex-direction:column;align-items:center;margin-bottom:24px}@media (min-width: 700px){.inventree{flex-direction:row;align-items:flex-start;justify-content:center;margin-top:16px}}.inventree__visuals{display:flex;flex-direction:column}@media (min-width: 700px){.inventree__visuals{margin-right:48px}}.inventree__icons{display:flex;width:280px;margin-top:12px;justify-content:space-around}@media (min-width: 700px){.inventree__icons{width:400px}}.inventree__icon{height:20px}@media (min-width: 700px){.inventree__icon{height:25px}}.inventree__screenshot{margin-top:16px;margin-bottom:8px;width:280px;border-radius:16px;box-shadow:0px 0px 10px 1px #524e61}@media (min-width: 700px){.inventree__screenshot{width:400px}}.inventree__description{width:280px;margin-top:24px}@media (min-width: 700px){.inventree__description{width:400px}}.inventree__text{font-size:12px;display:flex;padding-bottom:8px;align-items:center}@media (min-width: 700px){.inventree__text{font-size:20px;font-weight:300;padding-bottom:16px}}.inventree__text-left{width:100px}@media (min-width: 700px){.inventree__text-left{width:120px}}.inventree__text-right{width:200px;font-size:10px}@media (min-width: 700px){.inventree__text-right{font-size:14px;width:250px}}

.stalk{width:100%;color:#524e61;display:flex;flex-direction:column;align-items:center;margin-bottom:24px}@media (min-width: 700px){.stalk{flex-direction:row;align-items:flex-start;justify-content:center;margin-top:16px}}.stalk__visuals{display:flex;flex-direction:column}@media (min-width: 700px){.stalk__visuals{margin-right:48px}}.stalk__icons{display:flex;width:280px;margin-top:12px;justify-content:center}@media (min-width: 700px){.stalk__icons{width:400px}}.stalk__icon{height:20px;margin:0px 12px}@media (min-width: 700px){.stalk__icon{height:25px}}.stalk__screenshot{margin-top:16px;margin-bottom:8px;width:280px;border-radius:16px;box-shadow:0px 0px 10px 1px #524e61}@media (min-width: 700px){.stalk__screenshot{width:400px}}.stalk__description{width:280px;margin-top:24px}@media (min-width: 700px){.stalk__description{width:400px}}.stalk__text{font-size:12px;display:flex;padding-bottom:8px;align-items:center}@media (min-width: 700px){.stalk__text{font-size:20px;font-weight:300;padding-bottom:16px}}.stalk__text-left{width:100px}@media (min-width: 700px){.stalk__text-left{width:120px}}.stalk__text-right{width:200px;font-size:10px}@media (min-width: 700px){.stalk__text-right{font-size:14px;width:250px}}

