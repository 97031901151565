@import '../../partials/_mixins.scss';
@import '../../partials/_colors.scss';

.projects {
  width: 100%;
  color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  &__header {
    font-size: 24px;
    font-weight: 600;
    padding-top: 10vh;
    @include media($tabdesk) {
      font-size: 36px;
      font-weight: 500;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    color: $primary-dark;
    margin-bottom: 24px;
    border-bottom: 1px solid $primary-dark;
    @include media($tabdesk) {
      font-size: 24px;
      margin-top: 164px;
    }
  }
}