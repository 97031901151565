@import '../../partials/_mixins.scss';
@import '../../partials/_colors.scss';

.hero {
  height: 100vh;
  width: 100%;
  color: $primary-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 40px;
    font-weight: 400;
    width: 125px;
    @include media($tabdesk) {
      font-size: 60px;
    }
  }
  &__divider {
    height: 120px;
    width: 1px;
    border: 1px solid $primary-dark;
    margin: 0px 20px;
    @include media($tabdesk) {
      height: 200px;
    }
  }
  &__socials {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 11px;
    width: 125px;
    @include media($tabdesk) {
      font-size: 16px;
    }
  }
  &__socials-line {
    display: flex;
    margin: 5px 0px;
    align-items: center;
  }
  &__socials-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  &__icon {
    position: absolute;
    bottom: 175px;
    height: 20px;
    @include media($tabdesk) {
      height: 20px;
      bottom: 125px;
    }
  }
}